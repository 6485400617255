import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../context/AdminContext";

import AdminService from "../../api/services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniLoadingComponent from "../../components/MiniLoadingComponent";
import Constants from "../../constants/Constants";
import TopNavComponent from "../../components/admin/TopNavComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserDetailModalComponent from "../../components/modals/admin/UserDetailModalComponent";
import { faCheckCircle, faClockFour } from "@fortawesome/free-regular-svg-icons";
import { faPauseCircle } from "@fortawesome/free-solid-svg-icons";


let UsersPage = () => {
    let navigate = useNavigate();
    let { users, setUsers } = useContext(AdminContext);
    let [usersIsLoading, setUsersIsLoading] = useState(false);
    let [verifiedUsers, setVerifiedUsers] = useState(null);
    let [pendingUsers, setPendingUsers] = useState(null);
    let { userId } = useParams();
    let [fetchingUserDetail, setFetchingUserDetail] = useState(false);
    let [isUserModalActive, setUserModalActive] = useState(false);
    let [userDetails, setUserDetails] = useState(null);
    useEffect(() => {
        if (!users) {
            handleFetchUsers();
        }
        else if (users) {
            if (!verifiedUsers) {
                handleLoadUsers()
            }
        }

        if (userId) {
            if (!isUserModalActive) {
                if (fetchingUserDetail) {
                    return;
                }
                setUserModalActive(true)
                setFetchingUserDetail(true);
                handleFetchUserDetail(userId);

            }
        }
    })

    let handleCloseUserModal = async () => {

        setUserModalActive(false);
        navigate("/admin/dashboard/users")
    }

    let handleFetchUserDetail = async () => {
        try {
            let req = await AdminService.fetchUserDetail(userId);
            if (req.success) {
                setUserDetails(req.data[0]);
                setFetchingUserDetail(false);
            }
            else {
                toast.error("Unable to fetch user details");
                setFetchingUserDetail(false);
                handleCloseUserModal()


            }

        }
        catch (e) {
            console.log(e);
            toast.error("An error occured");
            setFetchingUserDetail(false);
            handleCloseUserModal()
        }

    }



    let handleLoadUsers = async () => {
        if (users) {
            setVerifiedUsers(users.filter(o => o.emailVerified == true))
            setPendingUsers(users.filter(o => o.emailVerified == false))
        }
    }
    let handleFetchUsers = async () => {
        setUsersIsLoading(true);
        let trans = await AdminService.fetchUsers();
        if (trans.success) {
            setUsers(trans.data);

            handleLoadUsers();
            setTimeout(() => {
                setUsersIsLoading(false);
            }, 2000)

        }
    }

    return <>
        <div className="font-lota lota-font g-rouzo-base-color h-screen w-screen p-1">
            {isUserModalActive ? <UserDetailModalComponent isLoading={fetchingUserDetail} closeFn={handleCloseUserModal} userDetail={userDetails}></UserDetailModalComponent> : ""}
            <div className="bg-rouzo-deep-blue h-full w-full rounded-xl p-2">
                <div className="flex flex-row p-4">
                    <div className="font-bold text-white text-xl">
                        <p>Hi Admin,</p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="px-10 flex flex-col justify-center gap-4">
                    <TopNavComponent usersActive={true}></TopNavComponent>
                    <div className="flex flex-row gap-2 justify-center">

                        <div className="flex flex-row items-center gap-1">

                            <div className="p-3 bg-white rounded-xl text-center flex flex-col gap-1">
                                {usersIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{users ? users.length : 0}</p>}
                                <p className="text-xs font-bold">Total users</p>
                            </div>

                            <div className="flex flex-col gap-1 ">
                                <div className="p-4  bg-white rounded-xl text-center flex flex-col gap-1">
                                    {usersIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-rouzo-orange-default">{verifiedUsers ? verifiedUsers.length : 0} <span className="text-xs text-black">verified users</span></p>}
                                </div>
                                <div className="p-4 bg-white rounded-xl text-center flex flex-col gap-1">
                                    {usersIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-black">{pendingUsers ? pendingUsers.length : 0} <span className="text-xs text-black">pending users</span></p>}

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="grid md:grid-cols-3 gap-2 grid-cols-1 overflow-y-auto max-h-80 md:max-h-96">
                        {users ? users.map((user, index) => {
                            return <>
                                <div className="rounded-md bg-white flex flex-row gap-2 p-2 pb-0 items-center">
                                    <p className="font-bold text-2xl p-2 rounded-full text-white bg-rouzo-base-color">
                                        {index + 1}
                                    </p>
                                    <div className="justify-center grow ">
                                        <div className="flex flex-col justify-center grow text-left gap-2">
                                            <p className="text-rouzo-base-color font-bold">{user.fullName}</p>
                                            <div className="flex flex-col  text-wrap text-center justify-center items-center">
                                                <div>

                                                </div><p className=" text-xs text-center text-wrap break-al">
                                                    {user.emailAddress}
                                                </p>
                                                <p>
                                                    {user.emailVerified ?
                                                        <p className="flex text-xs px-1 py-0 rounded-md items-center flex-row gap-1 bg-rouzo-green-light text-white">
                                                            Email verified
                                                            <FontAwesomeIcon className="text-white h-4" icon={faCheckCircle}>
                                                            </FontAwesomeIcon>
                                                        </p>
                                                        :
                                                        <p className="flex text-xs px-1 py-0 rounded-md items-center flex-row gap-1 bg-orange-400 text-white">
                                                            Email pending
                                                            <FontAwesomeIcon icon={faPauseCircle} className="text-white h-4"></FontAwesomeIcon>
                                                        </p>
                                                    }
                                                </p>
                                            </div>
                                            <p className="flex flex-row gap-1 items-center">
                                                <FontAwesomeIcon className="text-rouzo-base-color h-3" icon={faClockFour}></FontAwesomeIcon>
                                                <p className="text-xs font-bold text-rouzo-base-color">Joined: {new Date(user.joined).toDateString()}</p>
                                            </p>
                                        </div>
                                        <div className="justify-end text-right flex flex-row">
                                            <Link to={`/admin/dashboard/user/${user.userId}`}>
                                                <p className="rounded-t-md px-1 text-right bg-rouzo-deep-blue text-white hover:cursor-pointer hover:underline hover:underline-offset-1 ">
                                                    More
                                                </p>
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </>
                        }) : ""}

                    </div>
                </div>

            </div>


        </div>
    </>
}


export default UsersPage;