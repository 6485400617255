import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Constants from "../../constants/Constants"
import FormatMoneyComponent from "../misc/FormatMoneyComponent"
import { faTimesSquare } from "@fortawesome/free-solid-svg-icons"
import { faClock } from "@fortawesome/free-regular-svg-icons"
import { Link } from "react-router-dom"



let SavingplanPillComponent = ({ savingPlanData }) => {


    return <>
        <div className="flex flex-col rounded-md bg-white p-2 gap-2">
            <div className="flex flex-row justify-between gap-2">
                <div className="flex flex-col max-w-16 text-nowrap gap-1">
                    <p className="text-xs rounded-xl text-white font-bold bg-rouzo-base-color px-2 text-nowrap  ">{savingPlanData.savingChallengeType}</p>
                    <p className="text-xs rounded-xl text-white font-bold bg-rouzo-base-color px-2 text-nowrap ">{savingPlanData.savingPlanName}</p>
                </div>
                <div className="flex flex-col">
                    {savingPlanData.status == "active" ?
                        <p className="text-xs text-white font-bold bg-rouzo-green-light px-2 ">{savingPlanData.status}</p>
                        : ""
                    }
                    {savingPlanData.status == "pending" ?
                        <p className="text-xs text-white font-bold bg-gray-500 px-2 ">{savingPlanData.status}</p>
                        : ""
                    }
                    {savingPlanData.status == "matured" ?
                        <p className="text-xs text-white font-bold bg-rouzo-green-light px-2 ">{savingPlanData.status}</p>
                        : ""
                    }

                    <p className="text-xs text-white font-bold bg-rouzo-base-color px-2 text-overflow-x-hidden text-wrap ">{savingPlanData.userDetails ? savingPlanData.userDetails[0].fullName : ""}</p>


                </div>
            </div>
            <div className="flex flex-row justify-between gap-2 items-center">
                <div className="flex flex-col text-left" >
                    <p className="text-xs text-rouzo-base-color text-nowrap ">{Constants.nairaString}<FormatMoneyComponent amount={savingPlanData.amount}></FormatMoneyComponent> every month</p>
                    <span className="text-xs font-bold rounded-md text-white px-1 bg-rouzo-base-color text-nowrap ">{savingPlanData.isCard ? "card" : "stash"}</span>

                </div>
                <div className="flex-col gap-0">
                    <p className="mb-0">Total Value</p>
                    <div className="flex flex-row items-center">
                        <p className="text-xs text-black">{Constants.nairaString}</p>
                        <div className="font-bold text-xl text-rouzo-green-light">
                            <FormatMoneyComponent amount={savingPlanData.value}></FormatMoneyComponent>
                        </div>
                    </div>
                </div>

            </div>
            <div className="flex flex-col gap-2 text-xs font-bold">
                <p className="flex flex-row gap-2 items-center"><FontAwesomeIcon className="text-black " icon={faClock}></FontAwesomeIcon>Started At : {new Date(savingPlanData.createdAt).toDateString()}</p>
                <p className="flex flex-row gap-2 items-center"><FontAwesomeIcon className="text-black " icon={faClock}></FontAwesomeIcon>  {savingPlanData.status == "active" ? "Matures" : "Matured" } : {savingPlanData.endingAt ? new Date(savingPlanData.endingAt).toDateString() : "N/A"}</p>
            </div>
            <div className="px-2">
                <hr></hr>
            </div>
            <div className="flex gap-2 justify-between  flex-row">
                <div className="bg-rouzo-deep-blue px-2 hover:cursor-pointer font-bold text-white rounded-md ">
                    <Link to={`/admin/dashboard/user/${savingPlanData.userId}`}>
                    <p>View User</p>
                    </Link>
                </div>
                {savingPlanData.status == "active" ? <div className="bg-red-400 hover:cursor-pointer  px-2 font-bold text-white rounded-md ">
                    <p>Liquidate Plan</p>
                </div>
                    : ""}
                {/*<div className="bg-rouzo-deep-blue px-2 font-bold text-white rounded-md ">
                    <p></p>
                </div>*/}
            </div>
        </div>
    </>
}


export default SavingplanPillComponent