import { useContext } from "react";
import { AdminContext } from "../../context/AdminContext";
import SavingplanPillComponent from "./SavingplanPillComponent";




let SavingplansListComponent = () => {

    let { savingPlans } = useContext(AdminContext)
    return <>
        <div className="md:grid md:grid-cols-3 flex flex-col gap-4 md:p-6 ">
            {savingPlans ? savingPlans.map(sp => {
                return <SavingplanPillComponent savingPlanData={sp}></SavingplanPillComponent>
            }) : ""}
        </div>
    </>
}


export default SavingplansListComponent;