import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Constants from "../../constants/Constants";
import { faArrowAltCircleDown, faArrowAltCircleUp, faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import FormatMoneyComponent from "../misc/FormatMoneyComponent";
import { faClock } from "@fortawesome/free-regular-svg-icons";



let TransactionPillComponent = ({ transactonData }) => {


    return <>
        {transactonData.status == "success" ?
            <div className="bg-rouzo-deep-blue items-center flex flex-row border-2 gap-2 px-2 py-2 justify-between rounded-md border-rouzo-green-light">
                <div>
                    {transactonData.type == "debit" ?
                        <FontAwesomeIcon className="text-orange-400 text-xl" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                        : <FontAwesomeIcon className="text-rouzo-base-color text-xl" icon={faArrowAltCircleDown}></FontAwesomeIcon>}
                </div>
                <div className="flex flex-col gap-2 grow">
                    <div className="grid grid-cols-2 justify-end">
                        <p className="text-white text-md font-bold">{transactonData.userDetails ? transactonData.userDetails[0].fullName : ""}</p>
                        <p className="flex flex-row gap-2 text-white items-center text-xs font-bold">
                            <FontAwesomeIcon icon={faClock} ></FontAwesomeIcon>
                            <p>{transactonData.createdAt}</p>
                        </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-2 justify-between ">

                        <p className="text-white font-bold text-xs text-wrap">{transactonData._id}</p>

                        <p className="rounded-full text-xs text-black px-1 bg-white ">{transactonData.source}</p>
                        <p className="rounded-full text-xs text-white px-1 bg-rouzo-green-light ">{transactonData.status}</p>

                        <p className="rounded-full font-bold text-sm text-white px-1 bg-rouzo-green-light ">{Constants.nairaString} <FormatMoneyComponent amount={transactonData.amount}></FormatMoneyComponent></p>
                    </div>

                </div>
            </div>
            : ""
        }

        {transactonData.status == "pending" ?
            <div className="bg-rouzo-deep-blue items-center flex flex-row gap-2 border-2 rounded-md px-2 py-2 border-orange-400">
                <div>
                    {transactonData.type == "debit" ?
                        <FontAwesomeIcon className="text-orange-400 text-xl" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                        : <FontAwesomeIcon className="text-rouzo-base-color text-xl" icon={faArrowAltCircleDown}></FontAwesomeIcon>}
                </div>
                <div className="flex flex-col gap-2 grow">
                    <div className="grid grid-cols-2 justify-end">
                        <p className="text-white text-md font-bold">{transactonData.userDetails ? transactonData.userDetails[0].fullName : ""}</p>
                        <p className="flex flex-row gap-2 text-white items-center text-xs font-bold">
                            <FontAwesomeIcon icon={faClock} ></FontAwesomeIcon>
                            <p>{transactonData.createdAt}</p>
                        </p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-2 justify-between ">
                        <p className="text-white font-bold text-xs text-wrap">{transactonData._id}</p>

                        <p className="rounded-full text-xs text-black px-1 bg-white ">{transactonData.source}</p>
                        <p className="rounded-full text-xs text-white px-1  bg-orange-400 ">{transactonData.status}</p>

                        <p className="rounded-full font-bold text-sm text-white px-1 bg-orange-400 ">{Constants.nairaString} <FormatMoneyComponent amount={transactonData.amount}></FormatMoneyComponent></p>
                    </div>
                </div>
            </div>
            : ""
        }

        {transactonData.status == "failed" ?
            <div className="bg-rouzo-deep-blue items-center flex flex-row border-2 gap-2 rounded-md px-2 py-1 border-red-400">
                {transactonData.type == "debit" ?
                    <FontAwesomeIcon className="text-orange-400 text-sm" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                    : <FontAwesomeIcon className="text-rouzo-base-color text-sm" icon={faArrowCircleDown}></FontAwesomeIcon>}
                <p className="text-white font-bold text-xs text-wrap">{transactonData._id}</p>

                <p className="rounded-full text-xs text-black px-1 bg-white ">{transactonData.source}</p>
                <p className="rounded-full text-xs text-white px-1 bg-red-400 ">{transactonData.status}</p>

                <p className="rounded-full font-bold text-sm text-white px-1 bg-red-400 ">{Constants.nairaString} <FormatMoneyComponent amount={transactonData.amount}></FormatMoneyComponent></p>
            </div>
            : ""
        }
    </>

}


export default TransactionPillComponent;