import { Link } from "react-router-dom"


let TopNavComponent = ({ transactionsActive, savingplansActive,usersActive, walletsActive, homeActive,withdrawalsActive }) => {
    return <>
        <div className="grid grid-cols-6 border-rouzo-base-color border-b-2">
            <Link to={"/admin/dashboard/home"}><p className={`font-bold py-1 ${homeActive ? "bg-rouzo-base-color py-1 rounded-t-xl  text-white text-md" : " hover:cursor-pointer  text-white text-sm"}  `}>Home</p></Link>
            <Link to={"/admin/dashboard/users"}><p className={`font-bold py-1 ${usersActive ? "bg-rouzo-base-color py-1 rounded-t-xl  text-white text-md" : " hover:cursor-pointer  text-white text-sm"}  `}>Users</p></Link>
            <Link to={"/admin/dashboard/transactions"}><p  className={`font-bold py-1 ${transactionsActive ? "bg-rouzo-base-color py-1 rounded-t-xl  text-white text-md" : " hover:cursor-pointer  text-white text-sm"}  `}>Transactions</p></Link>
            <Link to={"/admin/dashboard/savingplans"}><p className={`font-bold py-1 ${savingplansActive ? "bg-rouzo-base-color py-1 rounded-t-xl  text-white text-md" : " hover:cursor-pointer  text-white text-sm"}  `}>Saving Plans</p></Link>
            <Link to={"/admin/dashboard/wallets"}><p className={`font-bold py-1 ${walletsActive ? "bg-rouzo-base-color py-1 rounded-t-xl  text-white text-md" : " hover:cursor-pointer  text-white text-sm"}  `}>Wallets</p></Link>
            <Link to={"/admin/dashboard/withdrawals"}><p className={`font-bold py-1 ${withdrawalsActive ? "bg-rouzo-base-color py-1 rounded-t-xl  text-white text-md" : " hover:cursor-pointer  text-white text-sm"}  `}>Withdrawals</p></Link>
        </div>
    </>
}


export default TopNavComponent