import { faArrowAltCircleDown, faArrowDown, faArrowCircleLeft, faArrowUpFromBracket, faInfoCircle, faMoneyBill, faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

import NewSavingPlan from "../components/modals/NewSavingPlan";
import { useContext, useEffect, useState } from "react";

import useAccounts from "../hooks/useAccount";
import { AccountContext } from "../context/AccountContext";
let TransactionsPage = () => {

    const { fetchTransactions } = useAccounts();
    let [isNewSavingPlanModalActive, setNewSavingPlanModalActive] = useState(false);
    const { transactions } = useContext(AccountContext);
    useEffect(() => {
        if (!transactions) {
            fetchTransactions()
        }
    })
    return <>
        {/*{isNewSavingPlanModalActive ? <NewSavingPlan setNewSavingPlanModalActive={setNewSavingPlanModalActive}></NewSavingPlan> : ""}*/}
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap-4">
                <div className="flex flex-row gap-4 items-center p-2 border-b-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Transactions</p>
                    </div>
                </div>
                <div className="px-4">
                    <div className="flex flex-col gap-4">
                        {
                            transactions && transactions.length > 0 ?
                                transactions.map(trans => {
                                    return <div>{
                                        (trans.type == "credit") ?
                                            (<div className="border flex flex-row gap-2 justify-between py-2 rounded-md px-4 border-2 items-center border-green-100 bg-green-50">
                                                <div className="justify-start flex flex-row items-center gap-4">
                                                    <FontAwesomeIcon className="text-green-400 text-sm" icon={faArrowAltCircleDown}></FontAwesomeIcon>
                                                    <p className="font-bold text-md ">NGN{trans.amount}</p>
                                                </div>
                                                {/*<p>{trans.amount}</p>*/}
                                                {trans.status == "pending" ?
                                                    <div className="rounded-xl rounded-full text-black border-2 border-black bg-rouzo-grey px-4 text-xs  ">
                                                        pending
                                                    </div>
                                                    : ""}
                                                {trans.status ==  "failed" ?
                                                    <div className="rounded-xl rounded-full text-white border-2 border-red-600 bg-red-400 px-4 text-xs  ">
                                                        failed
                                                    </div>
                                                    : ""}
                                                {trans.status == "success" ?
                                                    <div className="rounded-xl rounded-full text-white border-2 border-green-600 bg-green-400 px-4 text-xs  ">
                                                        success
                                                    </div>
                                                    : ""}
                                            </div>)
                                            : ""
                                    }
                                        {
                                            (trans.type == "debit") ?
                                                <div className="border flex flex-row gap-2 justify-between px-4 border-orange-100 bg-orange-50">
                                                    <div className="justify-start flex flex-row items-center gap-4">
                                                        <FontAwesomeIcon className="text-orange-400 text-sm" icon={faArrowAltCircleUp}></FontAwesomeIcon>
                                                        <p className="font-bold text-md ">NGN{trans.amount}</p>
                                                    </div>
                                                    
                                                </div>
                                                : ""
                                        }
                                    </div>
                                }) :
                                <div className="flex flex-col py-8">
                                    <div>
                                        <FontAwesomeIcon className="text-3xl text-rouzo-deep-bluee " icon={faArrowUpFromBracket}></FontAwesomeIcon>
                                    </div>
                                    <p className="rounded-xl px-2 text-rouzo-grey bg-rouzo-deep-blue">You have not performed any transaction.</p>
                                </div>
                        }

                    </div>
                </div>
            </div>
        </div>

    </>
}


export default TransactionsPage