import { useContext } from "react"
import { AdminContext } from "../../context/AdminContext"
import TransactionPillComponent from "./TransactionPillComponent";


let TransactionListComponent = () => {

    let { transactions } = useContext(AdminContext);

    return <>
        <div className="flex flex-col h-1/2 overflow-y-scroll gap-2">
            {transactions ? transactions.map(trans => {
                return <TransactionPillComponent transactonData={trans}></TransactionPillComponent>
            }) : "'"}
        </div>

    </>

}


export default TransactionListComponent