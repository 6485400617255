
import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../context/AdminContext";

import AdminService from "../../api/services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniLoadingComponent from "../../components/MiniLoadingComponent";
import Constants from "../../constants/Constants";
import TopNavComponent from "../../components/admin/TopNavComponent";
import WithdrawalPillComponent from "../../components/admin/WithdrawalPillComponent";
//import SidebarComponent from "../../components/admin/SidebarComponent";



let WithdrawalsPage = () => {


    let { withdrawals, setWithdrawals } = useContext(AdminContext);
    let [withdrawalsIsLoading, setWithdrawalsIsLoading] = useState(false);
    let [successfulWithdrawals, setSuccessfulWithdrawals] = useState(null);
    let [pendingWithdrawals, setPendingWithdrawals] = useState(null);
    let [failedWithdrawals, setFailedWithdrawals] = useState(null);
    useEffect(() => {
        if (!withdrawals) {
            handleFetchWithdrawals();
        }
        else if (withdrawals) {
            if (!successfulWithdrawals) {
                handleLoadWithdrawals()
            }
        }
    })

    let handleLoadWithdrawals = async () => {
        if (withdrawals) {
            setSuccessfulWithdrawals(withdrawals.filter(o => o.status == "success"))
            setPendingWithdrawals(withdrawals.filter(o => o.status == "pending"))
            setFailedWithdrawals(withdrawals.filter(o => o.status == "failed"))
        }
    }
    let handleFetchWithdrawals = async () => {
        setWithdrawalsIsLoading(true);
        let trans = await AdminService.fetchWithdrawals();
        if (trans.success) {
            setWithdrawals(trans.data);

            handleLoadWithdrawals();
            setTimeout(() => {
                setWithdrawalsIsLoading(false);
            }, 2000)

        }
    }

    return <>
        <div className="font-lota lota-font g-rouzo-base-color h-screen w-screen p-1">

            <div className="bg-rouzo-deep-blue h-full w-full rounded-xl p-2">
                {/*<SidebarComponent></SidebarComponent>*/}
                <div className="flex flex-row p-4">
                    <div className="font-bold text-white text-xl">
                        <p>Hi Admin,</p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="px-2 md:px-10 flex flex-col justify-center gap-4">
                    <TopNavComponent withdrawalsActive={true}></TopNavComponent>
                    <div className="flex flex-row gap-2 justify-center">

                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-3  text-center flex flex-col gap-1">
                                {withdrawalsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{withdrawals ? withdrawals.length : 0}</p>}
                                <p className="font-bold">Total Withdrawals</p>
                            </div>

                        </div>
                        <div className="flex flex-row items-center gap-1">

                            <div className="p-3 bg-white rounded-xl text-center flex flex-col gap-1">
                                {withdrawalsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{successfulWithdrawals ? successfulWithdrawals.length : 0}</p>}
                                <p className="text-xs font-bold">successful withdrawals</p>
                            </div>

                            <div className="flex flex-col gap-1 ">
                                <div className="p-4  bg-white rounded-xl text-center flex flex-col gap-1">
                                    {withdrawalsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-rouzo-orange-default">{failedWithdrawals ? failedWithdrawals.length : 0} <span className="text-xs text-black">failed withdrawals</span></p>}
                                </div>
                                <div className="p-4 bg-white rounded-xl text-center flex flex-col gap-1">
                                    {withdrawalsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-black">{pendingWithdrawals ? pendingWithdrawals.length : 0} <span className="text-xs text-black">pending withdrawals</span></p>}

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col ">
                        {withdrawals ? withdrawals.map((wd, index) => {
                            return <WithdrawalPillComponent
                                accountName={wd.withdrawalAccountDetails ? wd.withdrawalAccountDetails.accountName : null}
                                accountNumber={wd.withdrawalAccountDetails ? wd.withdrawalAccountDetails.accountNumber : null}
                                bankName={wd.withdrawalAccountDetails ? wd.withdrawalAccountDetails?.bankName : null}
                                fetchWithdrawals={handleFetchWithdrawals}
                                status={wd.status}
                                index={index}
                                amount={wd.amount}
                                emailAddress={wd.emailAddress}
                                withdrawalId={wd.withdrawalId}
                                walletBalance = {wd.walletBalance}
                                risks = {wd.risks}
                                userId = {wd.userId}
                                >

                            </WithdrawalPillComponent>
                            {/*<WithdrawalPillComponent status={wd.status} index={index} amount={wd.amount} emailAddress={wd.emailAddress} withdrawalId={wd.withdrawalId} ></WithdrawalPillComponent>*/ }
                        }) : "None"}

                    </div>
                </div>

            </div>


        </div>
    </>
}


export default WithdrawalsPage;