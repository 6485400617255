import { faArrowCircleLeft, faInfoCircle, faMoneyBill } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom";
let RankImg = require("../../assets/img/rank_img.jpg");

let LeaderboardChallengeList = () => {
    return <>
        <div className="w-screen font-lota flex flex-col lota-font items-center h-screen justify-items-center">
            <div className="flex flex-col w-full h-full md:w-auto gap-8 px-2 pt-4">
                <div className="flex flex-row gap-4 border-b-2 border-rouzo-grey">
                    <Link to={"/dashboard"}>
                        <div>
                            <FontAwesomeIcon className="" icon={faArrowCircleLeft}></FontAwesomeIcon>
                        </div>
                    </Link>
                    <div className="flex flex-grow justify-center text-center">
                        <p className="text-lg font-bold text-center">Leaderboards</p>
                    </div>
                </div>
                <div className="px-2">
                    <div className=" flex flex-col gap-2">
                        <div className="bg-white rounded-xl">
                            <div className="md:h-24 h-26 w-full rounded-lg">
                                <img className="md:h-24 h-26 w-full rounded-xl" src={RankImg}></img>
                            </div>
                        </div>

                        <p className="bg-rouzo-grey rounded-xl py-4 text-rouzo-green-light px-4 text-sm text-left">
                            Rank up more points as you save more money.
                            <br></br>
                            Save to the top and become the boss of the weekly trophy.
                            <br></br>Check out your weekly position
                        </p>
                    </div>
                    <div className="py-2 px-4 flex flex-col gap-4">
                        <Link to={"/leaderboard/agbaballer"}>
                            <div className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">Agba Baller</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>
                                <div className="flex flex-col py-4">

                                </div>

                            </div>

                        </Link>
                        <Link to={"/leaderboard/odogwu"}>
                            <div className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">Odogwu</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>


                            </div>

                        </Link>
                        <Link to={"/leaderboard/egobetter"}>
                            <div className="rounded-lg text-left flex gap-8 justify-between flex-row px-4 bg-rouzo-grey items-center ">
                                <div className="flex-grow text-left">
                                    <div className="flex flex-row items-center gap-2 ">
                                        <p className="font-bold ">E go better</p>
                                        <FontAwesomeIcon className="text-rouzo-base-color text-xs" icon={faInfoCircle}></FontAwesomeIcon>
                                    </div>
                                </div>

                            </div>

                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default LeaderboardChallengeList;