

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Routes
} from "react-router-dom";

import Layout from "../layouts/Layout";

import HomePage from "../pages/HomePage";
import AdminDashboardPage from "../pages/AdminDashboardPage";
import CollectionPage from "../pages/CollectionPage";
import AdminLayout from "../layouts/AdminLayout";
import SignupPage from "../pages/SignupPage";
import LoginPage from "../pages/LoginPage";
import DashboardPage from "../pages/DashboardPage";
import ChallengesPage from "../pages/ChallengesPage";
import LeaderboardChallengeList from "../pages/leaderboards/LeaderboardChallengeList";

import AdminTransactionsPage from "../pages/admin/TransactionsPage";
import AdminUsersPage from "../pages/admin/UsersPage";
import AdminWithdrawalsPage from "../pages/admin/WithdrawalsPage";
import AdminSavingPlansPage from "../pages/admin/SavingPlansPage";
import AdminWalletsPage from "../pages/admin/WalletsPage"

import TransactionsPage from "../pages/TransactionsPage";
import PrivateRoute from "./privateRoute";
import AccountLayout from "../layouts/AccountLayout";
import OnboardLayout from "../layouts/OnboardLayout";
import EmailVerificationPage from "../pages/email/EmailVerificationPage";
import EmailVerificationStatusPage from "../pages/email/EmailVerificationStatusPage";
import SavingPlansPage from "../pages/SavingPlansPage";
import BankPage from "../pages/BankPage";
import LeaderboardPage from "../pages/LeaderboardPage";
import AdminMainDashboardPage from "../pages/AdminMainDashboardPage";


const homeRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<Layout />}>
        <Route element={<OnboardLayout />}>
          <Route path="/" element={<LoginPage></LoginPage>}></Route>
          {/*<Route path="/onboard" element={<SignupPage></SignupPage>}></Route>*/}
          <Route path="/login" element={<LoginPage></LoginPage>}></Route>
          {/*<Route path="/verify/:status/:message" element={<EmailVerificationStatusPage></EmailVerificationStatusPage>}></Route>
          <Route path="/email/verify/:emailAddress/:verificationAuthCode" element={<EmailVerificationPage></EmailVerificationPage>}></Route>*/}
        </Route>

      </Route>

      <Route element={<Layout />}>
        <Route element={<PrivateRoute />}>
          {/*<Route element={<AccountLayout />}>
            <Route path="/dashboard" element={<DashboardPage></DashboardPage>}></Route>
            <Route path="/saving-plans" element={<SavingPlansPage></SavingPlansPage>}></Route>
            <Route path="/save/challenges" element={<ChallengesPage></ChallengesPage>}></Route>
            
            <Route path="/leaderboards" element={<LeaderboardPage></LeaderboardPage>}></Route>
            <Route path="/transactions" element={<TransactionsPage></TransactionsPage>}></Route>

            
          </Route>*/}
          <Route element={<AdminLayout />}>
            <Route path="/admin/dashboard/home" element={<AdminMainDashboardPage></AdminMainDashboardPage>}></Route>
            <Route path="/admin/dashboard/transactions" element={<AdminTransactionsPage></AdminTransactionsPage>}></Route>
            <Route path="/admin/dashboard/users" element={<AdminUsersPage></AdminUsersPage>}></Route>
            <Route path="/admin/dashboard/user/:userId" element={<AdminUsersPage></AdminUsersPage>}></Route>
            <Route path="/admin/dashboard/savingplans" element={<AdminSavingPlansPage></AdminSavingPlansPage>}></Route>
            <Route path="/admin/dashboard/withdrawals" element={<AdminWithdrawalsPage></AdminWithdrawalsPage>}></Route>
            <Route path="/admin/dashboard/wallets" element={<AdminWalletsPage></AdminWalletsPage>}></Route>
          </Route>
        </Route>
      </Route>
    </Route >

  )
);


export default homeRouter;
