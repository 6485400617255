import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalRootComponent from "../ModalRootComponent";
import { faCheck, faCheckCircle, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faPauseCircle, faXmarkCircle } from "@fortawesome/free-regular-svg-icons";
import Constants from "../../../constants/Constants";
import { useState } from "react";
import SavingplanPillComponent from "../../admin/SavingplanPillComponent";
import SavingplanPillComponent2 from "../../admin/SavingplanPillComponent2";



let UserDetailModalComponent = ({ userDetail, isLoading, closeFn }) => {


    let [savingPlanActive, setSavingPlanActive] = useState(true);
    let [transactionsActive, setTransactionsActive] = useState(false);

    return <>
        <ModalRootComponent children={
            <div className="z20 font-lota lota-font rounded-2xl self-center p-2 bg-rouzo-base-color">
                <div className="flex flex-row gap-4 justify-between">
                    <div className="text-center grow">
                        {!isLoading ?
                            userDetail ?
                                <div className="text-center ">
                                    <p className="font-bold text-white text-md">  {userDetail.fullName}</p>
                                </div>
                                : ""

                            : ""}
                    </div>
                    <p className="text-right hover:cursor-pointer" onClick={closeFn}>
                        <FontAwesomeIcon icon={faXmarkCircle} className="text-white font-bold "></FontAwesomeIcon>
                    </p>

                </div>

                {isLoading ?
                    <div className="flex flex-col gap-2 p-6 text-white">
                        <FontAwesomeIcon className="h-10 text-white" icon={faSpinner} spin={true}></FontAwesomeIcon>
                        <p className="">Fetching user details ..</p>
                    </div>

                    :
                    <div className="p-4 ">
                        <div className=" flex flex-col gap-4">
                            <div className="flex flex-col gap-4 md:flex-row">
                                <div className="flex flex-col gap-2">
                                    <p className="text-white font-thin md:text-left text-center">Profile Information</p>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                                        <div className="flex flex-col gap-1 text-rouzo-base-color">
                                            <p className="text-xs px-1 bg-white text-rouzo-deep-blue rounded-md">Full Name</p>
                                            <p className="bg-white rounded-md px-2  font-bold">{userDetail.fullName}</p>
                                        </div>
                                        <div className="flex flex-col gap-1 text-rouzo-base-color">
                                            <p className="text-xs px-1 pr-0 bg-white rounded-md text-rouzo-deep-blue flex flex-row items-center justify-center">
                                                <p className="text-center grow">Email address</p>
                                                {userDetail.emailVerified == true ?
                                                    <div className="text-xs text-white bg-rouzo-green-light rounded-full px-1 flex flex-row items-center justify-end">
                                                        <FontAwesomeIcon icon={faCheckCircle}></FontAwesomeIcon>
                                                        <p>Verified</p>
                                                    </div>
                                                    : <div className="text-xs text-white bg-orange-400 rounded-full px-1 flex flex-row items-center justify-end">
                                                        <FontAwesomeIcon icon={faPauseCircle}></FontAwesomeIcon>
                                                        <p>Pending</p>
                                                    </div>}
                                            </p>
                                            <p className="bg-white rounded-md px-2  font-bold">{userDetail.emailAddress}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="md:hidden "></hr>
                                <div className="flex flex-col gap-2">
                                    <p className="text-white text-center md:text-left font-thin">Wallet </p>
                                    <div className="rounded-xl bg-rouzo-deep-blue outline outline-1 w-min-20 outline-white p-2">
                                        <p className="text-white text-left text-xs">Wallet Balance</p>
                                        <p className="text-xl text-right text-white font-bold">{Constants.nairaString} {userDetail.wallet ? userDetail.wallet[0].balance.stash : "N/A"}</p>
                                        <div>

                                        </div>
                                    </div>

                                </div>




                            </div>
                            <hr className=" "></hr>
                            <div>
                                <div className="flex flex-row gap-2">
                                    <div className="bg-rouzo-light-blue-2 font-bold  rounded-sm px-1 text-xs">
                                        <p>Saving Plans</p>
                                    </div>
                                    <div className="text-rouzo-grey font-bold  rounded-sm px-1 text-xs">
                                        <p>Transactions</p>
                                    </div>

                                </div>
                                {
                                    savingPlanActive && !transactionsActive ?
                                        <div className="p-2 pl-0">
                                            <div>
                                            </div>
                                            {
                                                userDetail.savingPlans ?
                                                    <div className="grid grid-cols-1  md:grid-cols-3 gap-2 overflow-y-auto max-h-80 md:max-h-96">
                                                        {
                                                            userDetail.savingPlans.length == 0 ? <p className="items-center text-center text-white font-bold text-xl">User has not created a saving plan</p> : ""
                                                        }
                                                        {userDetail.savingPlans.map(plan => {
                                                            return<SavingplanPillComponent2 savingPlanData={plan}>
                                                            </SavingplanPillComponent2>

                                                        })}
                                                    </div>
                                                    : <p className="items-center text-center text-white font-bold text-xl">User has not created a saving plan</p>
                                            }
                                        </div>
                                        : ""
                                }
                                {
                                    !savingPlanActive && transactionsActive ?
                                        <div className="p-2 pl-0">
                                            <div>
                                            </div>
                                            {
                                                userDetail.savingPlans ?
                                                    <div className="grid grid-cols-1  md:grid-cols-3 gap-2">
                                                        {userDetail.savingPlans.map(plan => {
                                                            return<SavingplanPillComponent2 savingPlanData={plan}>
                                                            </SavingplanPillComponent2>

                                                        })}
                                                    </div>
                                                    : <p className="items-center text-center text-white font-bold text-xl">User has not created a saving plan</p>
                                            }
                                        </div>
                                        : ""
                                }

                            </div>

                        </div>

                    </div>
                }
            </div>
        }>
        </ModalRootComponent>
    </>
}


export default UserDetailModalComponent;