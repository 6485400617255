import { useState, React, createContext } from "react";


export const AdminContext = createContext();

// Create an exportable consumer that can be injected into components
export const AdminConsumer = AdminContext.Consumer
// Create the provider using a traditional React.Component class

const AdminProvider = ({ children }) => {
    let [savingPlans, setSavingPlans] = useState(null);
    let [users, setUsers] = useState(null);
    let [transactions, setTransactions] = useState(null);
    let [withdrawals, setWithdrawals] = useState(null);
    let [wallets, setWallets] = useState(null);
    let [totalActiveSavingsValue, setTotalActiveSavingsValue] = useState(null);
    let [totalSavingsValue, setTotalSavingsValue] = useState(null);
    let [activeSavingPlans, setActiveSavingPlans] = useState(null);
    let [platformSummary, setPlatformSummary] = useState(null);

    return (
        // value prop is where we define what values 
        // that are accessible to consumer components
        <AdminContext.Provider value={{
            transactions, setTransactions,
            savingPlans, setSavingPlans,
            users, setUsers,
            wallets, setWallets,
            withdrawals, setWithdrawals,
            totalActiveSavingsValue, setTotalActiveSavingsValue,
            totalSavingsValue, setTotalSavingsValue,
            activeSavingPlans, setActiveSavingPlans,
            platformSummary, setPlatformSummary

        }}>
            {children}
        </AdminContext.Provider>
    )
}
export default AdminProvider
