import MiniLoadingComponent from "../MiniLoadingComponent";



let AdminCardDetailComponent = ({padding, isLoading , cardValue, title}) => {
    return <>
        <div className={`${padding ? padding : 'p-4'} bg-white rounded-xl text-center flex flex-col gap-1`}>
            {isLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-xl font-bold text-black">{cardValue} <span className="text-xs text-black">{title}</span></p>}

        </div>
    </>
}

export default AdminCardDetailComponent;