function authHeader() {
    const token = localStorage.getItem("rouzo_save_token");
  
    if (token) {
      return token;
    } else {
      return null;
    }
  }
  
  export default authHeader;
  