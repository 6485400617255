import { toast } from "react-toastify";
import AdminService from "../../api/services/AdminService"
import { useState } from "react";
import Constants from "../../constants/Constants";
import { Link } from "react-router-dom";


let WithdrawalPillComponent = ({ risks, userId, fetchWithdrawals, status, index, emailAddress, amount, withdrawalId, accountName, accountNumber, bankName, walletBalance }) => {

    let [isLoading, setIsLoading] = useState(false);
    let rejectWithdrawal = async () => {

        if (isLoading) {
            return
        }

        setIsLoading(true)

        let withdraw = await AdminService.rejectWithdrawal(withdrawalId);
        if (withdraw.success) {
            toast.success("Withdrawal has been rejected");
            fetchWithdrawals();
        }
        else {
            toast.error(withdraw.msg)
        }
        setIsLoading(false)
    }
    let approveWithdrawal = async () => {
        if (isLoading) {
            return
        }

        setIsLoading(true)
        let withdraw = await AdminService.approveWithdrawal(withdrawalId);
        if (withdraw.success) {
            toast.success("Withdrawal has been approved and transfer would be made.");
            fetchWithdrawals();
        }
        else {
            toast.error(withdraw.msg)
        }
        setIsLoading(false)
    }
    return <>
        <div className="text-black bg-white outline outline-1 flex flex-col gap-2 justify-between outline-rouzo-base-color py-2 px-4 ">
            <div className="flex-row flex gap-2  items-center ">
                <p>{index + 1}</p>
                <div className="items-center justify-between grow gap-2 flex flex-row">
                    <Link to={`/admin/dashboard/user/${userId}`}>
                        <p className="hover:underline hover:underline-offset-2 font-bold text-xs underline underline-offset-1">{emailAddress}</p>
                    </Link>
                    <p className="font-bold  text-md rounded-md text-rouzo-green-light bg-black px-2 py-1 ">{Constants.nairaString}{amount}</p>

                </div>
            </div>
            <div className="hidden md:grid grid-cols-4">
                <div className="flex flex-col gap-2">
                    <p className="text-xs">Account Name</p>
                    <p className="font-bold text-xs">{accountName ? accountName.toUpperCase() : "N/A"}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-xs">Account Number</p>
                    <p className="font-bold ">{accountNumber ? accountNumber : "N/A"}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-xs">Bank Name</p>
                    <p className="font-bold ">{bankName ? bankName : "N/A"}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="text-xs">Current Wallet Balance</p>
                    <p className="font-bold ">{walletBalance ? `${Constants.nairaString}${walletBalance.balance.stash}` : "N/A"}</p>
                </div>

            </div>
            <div className="md:hidden flex flex-col">
                <div className="flex flex-row items-center gap-2">
                    <p className="text-xs">Account Name</p>
                    <p className="font-bold text-xs">{accountName ? accountName.toUpperCase() : "N/A"}</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <p className="text-xs">Account Number</p>
                    <p className="font-bold ">{accountNumber ? accountNumber : "N/A"}</p>
                </div>
                <div className="flex flex-row items-center gap-2">
                    <p className="text-xs">Bank Name</p>
                    <p className="font-bold ">{bankName ? bankName : "N/A"}</p>
                </div>
                <div className="flex flex-row gap-2">
                    <p className="text-xs">Current Wallet Balance</p>
                    <p className="font-bold ">{walletBalance ? `${Constants.nairaString}${walletBalance.balance.stash}` : "N/A"}</p>
                </div>
            </div>
            <div>
                {risks ?
                    <div className="flex flex-row">
                        {risks.isPossibleDuplicate ? 
                            <div className="bg-yellow-400 px-2 py-1 text-xs font-bold text-white rounded-full">
                                Possible Duplicate
                            </div>
                            :""
                        }
                    </div>
                    : ""
                }
            </div>
            {(status == "pending") ?
                <div className="flex flex-row gap-2 justify-center">
                    <div onClick={() => approveWithdrawal()} className="bg-rouzo-green-light p-4 py-1 font-bold text-white hover:cursor-pointer hover:bg-green-600">
                        <p>Approve</p>
                    </div>
                    <div onClick={() => rejectWithdrawal()} className="bg-red-400 text-white font-bold p-4 py-1 hover:cursor-pointer hover:bg-red-600">
                        <p>Reject</p>
                    </div>
                </div>
                : <div className="bg-rouzo-blue-light px-2 py-1 rounded-full">
                    <p>{status ? status : "pending"}</p>
                </div>}

        </div>

    </>
}

export default WithdrawalPillComponent;