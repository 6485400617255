import Constants from "../../constants/Constants";
import axios from "../axios";
import authHeader from "./auth-header";
let opt = {
    headers: {
        "Authorization": `${authHeader()}`
    }
}
const AdminService = {
    fetchUsers : async () => {
        let url = Constants.ADMIN.FETCH_USERS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchUserDetail : async (userId) => {
        let url = Constants.ADMIN.FETCH_USER(userId);
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchTransactions : async () => {
        let url = Constants.ADMIN.FETCH_TRANSACTIONS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchSavingPlans: async () => {
        //console.log(reference);
        let url = Constants.ADMIN.FETCH_SAVINGPLANS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchWithdrawals : async () => {
        let url = Constants.ADMIN.FETCH_WITHDRAWALS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchWallets : async () => {
        let url = Constants.ADMIN.FETCH_WALLETS;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    fetchLeaderboards : async () => {
        return []
    },
    approveWithdrawal : async (withdrawalId) =>{
        let url = Constants.ADMIN.WITHDRAWALS.APPROVE_ONE(withdrawalId);
        let req = await axios.patch(url,{},opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    rejectWithdrawal : async (withdrawalId) => {
        let url = Constants.ADMIN.WITHDRAWALS.REJECT_ONE(withdrawalId);
        let req = await axios.patch(url,{},opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    },
    platformSummary : async () => {
        let url = Constants.ADMIN.PLATFORM.SUMMARY;
        let req = await axios.get(url,opt);
        if (req.data) {
            let { type, data } = req.data;
            console.log(type)
        }
        return req.data;
    }
};

export default AdminService;
