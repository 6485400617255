import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



let MiniLoadingComponent = ({color, height, fontSize}) => {
    return <>
        <FontAwesomeIcon className={`${color ? color : "text-white"} ${fontSize ? fontSize : 'text-md' }` } spinPulse={true} icon={faSpinner} spin /> 
    </>
}


export default MiniLoadingComponent;