import { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import AuthService from "../api/services/AuthService";
import { useAuthContext } from "../hooks/useAuth";
const useSignIn = () => {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isAborted, setIsAborted] = useState(false);
    const [error, setError] = useState(null);

    const signIn = async (userdata) => {
        setIsLoading(true);
        setError(null);
        console.log(userdata);
        try {
            const res = await AuthService.login(userdata);
            let {success, data } = res;
            console.log("Result", res);
            if (success) {
                let { data} = res;
                console.log(data)
                //console.log(authorization);
                localStorage.setItem("rouzo_save_token", data.token);
                localStorage.setItem("rouzo_save_user", JSON.stringify(data.user));


                dispatch({
                    type: "LOGIN",
                    payload: res.data
                });
                /*toast.success(`${res.message} \n Redirecting shortly`);
                setInterval(() => {
                    window.location.href="/dashboard"
                }, 2000);
                //fetchAllAccounts();
                */
            }
           
            //setIsLoading(false);

            /*if (!isAborted) {
                setIsLoading(false);
                setError(null);
            }*/

            return res;
        } catch (error) {
            //setIsLoading(false);
            //##TODO: ERROR TOAST
          
            //errorToast(error.message);
            let {response}= error;
            if(response) {
                let {data} = response;
                if(data){
                    if(data.message) {
                        toast.error(data.error);
                    }
                }
            }
            else{
                toast.error("An error occured");
            }
            return {
                networkError : true,
                errors: error
            }
            console.log(error)
        }
    };

    useEffect(() => {
        return () => {
            setIsAborted(true);
        };
    }, []);

    return {
        isLoading,
        error,
        signIn,
    };
};

export default useSignIn;
