import { Outlet } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import AdminProvider from "../context/AdminContext";

const AdminLayout = ({children}) => {
    return (
        <main className="">
            {children ? children : <AdminProvider>
                <ToastContainer/>
                <Outlet />
            </AdminProvider>}
        </main>
    )
}

export default AdminLayout;