import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../context/AdminContext";

import AdminService from "../../api/services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniLoadingComponent from "../../components/MiniLoadingComponent";
import Constants from "../../constants/Constants";
import TopNavComponent from "../../components/admin/TopNavComponent";
import TransactionListComponent from "../../components/admin/TransactionListComponent";


let TransactionsPage = () => {

    let { transactions, setTransactions } = useContext(AdminContext);
    let [transactionsIsLoading, setTransactionsIsLoading] = useState(false);
    let [successfulTransactions, setSuccessfulTransactions] = useState(null);
    let [pendingTransactions, setPendingTransactions] = useState(null);
    let [failedTransactions, setFailedTransactions] = useState(null);
    useEffect(() => {
        if (!transactions) {
            handleFetchTransactions();
        }
        else if (transactions) {
            if (!successfulTransactions) {
                handleLoadTransactions()
            }
        }
    })

    let handleLoadTransactions = async () => {
        if (transactions) {
            setSuccessfulTransactions(transactions.filter(o => o.status == "success"))
            setPendingTransactions(transactions.filter(o => o.status == "pending"))
            setFailedTransactions(transactions.filter(o => o.status == "failed"))
        }
    }
    let handleFetchTransactions = async () => {
        setTransactionsIsLoading(true);
        let trans = await AdminService.fetchTransactions();
        if (trans.success) {
            setTransactions(trans.data.reverse());

            handleLoadTransactions();
            setTimeout(() => {
                setTransactionsIsLoading(false);
            }, 2000)

        }
    }

    return <>
        <div className="font-lota lota-font g-rouzo-base-color h-screen w-screen p-1">
            <div className="bg-rouzo-deep-blue h-full w-full h-screen rounded-xl p-2">
                <div className="flex flex-row p-4">
                    <div className="font-bold text-white text-xl">
                        <p>Hi Admin,</p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className=" px-10 flex  flex-col justify-center gap-4">
                    <TopNavComponent transactionsActive={true}></TopNavComponent>
                    <div className="flex flex-row gap-2 justify-center">

                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-3  text-center flex flex-col gap-1">
                                {transactionsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{transactions ? transactions.length : 0}</p>}
                                <p className="font-bold">Total Transactions</p>
                            </div>

                        </div>
                        <div className="flex flex-row items-center gap-1">

                            <div className="p-3 bg-white rounded-xl text-center flex flex-col gap-1">
                                {transactionsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{successfulTransactions ? successfulTransactions.length : 0}</p>}
                                <p className="text-xs font-bold">successful transactions</p>
                            </div>

                            <div className="flex flex-col gap-1 ">
                                <div className="p-4  bg-white rounded-xl text-center flex flex-col gap-1">
                                    {transactionsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-rouzo-orange-default">{failedTransactions ? failedTransactions.length : 0} <span className="text-xs text-black">failed transactions</span></p>}
                                </div>
                                <div className="p-4 bg-white rounded-xl text-center flex flex-col gap-1">
                                    {transactionsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-black">{pendingTransactions ? pendingTransactions.length : 0} <span className="text-xs text-black">pending transactions</span></p>}

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="h-[800px]">
                        <div>

                        </div>
                        <TransactionListComponent></TransactionListComponent>
                    </div>
                </div>

            </div>


        </div>
    </>
}


export default TransactionsPage;