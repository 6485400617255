import { useContext, useEffect, useState } from "react";
import AdminService from "../api/services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniLoadingComponent from "../components/MiniLoadingComponent";
import Constants from "../constants/Constants";
import { AdminContext } from "../context/AdminContext";
import TopNavComponent from "../components/admin/TopNavComponent";
import FormatMoneyComponent from "../components/misc/FormatMoneyComponent";
import { faChartLine } from "@fortawesome/free-solid-svg-icons";



let AdminMainDashboardPage = () => {

    let { transactions, setTransactions } = useContext(AdminContext);
    let { users, setUsers } = useContext(AdminContext);
    let { savingPlans, setSavingPlans } = useContext(AdminContext);
    let { totalActiveSavingsValue, setTotalActiveSavingsValue } = useContext(AdminContext);
    let { totalSavingsValue, setTotalSavingsValue } = useContext(AdminContext);
    let { activeSavingPlans, setActiveSavingPlans } = useContext(AdminContext);
    let { platformSummary, setPlatformSummary } = useContext(AdminContext);
    let { wallets, setWallets } = useContext(AdminContext);
    let [transactionsIsLoading, setTransactionsIsLoading] = useState(false);
    let [usersIsLoading, setUsersIsLoading] = useState(false);
    let [savingPlansIsLoading, setSavingPlansIsLoading] = useState(false);
    let [totalWalletsValue, setTotalWalletsValue] = useState(null)
    let [transactionalValue, setTransactionalValue] = useState(null);

    let [todayActive, setTodayActive] = useState(true);
    let [thisWeekActive, setThisWeekActive] = useState(false);
    let [thisMonthActive, setThisMonthActive] = useState(false);

    let [isLoading, setIsLoading] = useState(false);

    let handleSwitchSummaryTimeline = async (when) => {
        switch (when) {
            case "today":
                setThisMonthActive(false);
                setThisWeekActive(false);
                setTodayActive(true);

                break;
            case "thisWeek":
                setThisMonthActive(false);

                setTodayActive(false);
                setThisWeekActive(true);
                break;
            case "thisMonth":

                setTodayActive(false);
                setThisWeekActive(false);
                setThisMonthActive(true);
                break;

            default:
                setThisMonthActive(false);
                setTodayActive(true);
                setThisWeekActive(false);
                break;
        }
    }
    let handleDashboardLoad = async () => {
        if (isLoading) {
            return
        }
        setIsLoading(true)
        if (!users) {
            await handleFetchUsers();
        }
        if (!transactions) {
            await handleFetchTransactions();
        }
        if (!savingPlans) {
            await handleFetchSavingPlans();
        }
        if (!totalWalletsValue) {
            if (wallets) {
                setTotalWalletsValue(wallets.reduce((a, b) => { return a + b.balance.stash }, 0))
            }
            else {
                await handleFetchWallets()
            }
        }
        if (!platformSummary) {
            await handleFetchPlatformSummary();
        }
        setIsLoading(false);
    }
    useEffect(() => {
        if (!totalWalletsValue) {
            if (wallets) {
                setTotalWalletsValue(wallets.reduce((a, b) => { return a + b.balance.stash }, 0))
            }
        }
        if (!transactionalValue) {

            if (totalWalletsValue && totalActiveSavingsValue) {
                setTransactionalValue(totalWalletsValue + totalActiveSavingsValue)
            }
        }
        handleDashboardLoad()

    })

    let handleFetchPlatformSummary = async () => {
        let req = await AdminService.platformSummary();
        if (req.success) {
            setPlatformSummary(req.data);
        }
    }

    let handleFetchWallets = async () => {
        let trans = await AdminService.fetchWallets();
        if (trans.success) {
            setWallets(trans.data);

        }
    }
    let handleFetchTransactions = async () => {
        setTransactionsIsLoading(true);
        let trans = await AdminService.fetchTransactions();
        if (trans.success) {
            setTransactions(trans.data);
            setTimeout(() => {
                setTransactionsIsLoading(false);
            }, 2000)

        }
    }
    let handleFetchUsers = async () => {
        setUsersIsLoading(true);
        let trans = await AdminService.fetchUsers();
        if (trans.success) {
            setUsers(trans.data);
            setUsersIsLoading(false);
        }
    }
    let handleFetchSavingPlans = async () => {
        setSavingPlansIsLoading(true);
        let trans = await AdminService.fetchSavingPlans();
        if (trans.success) {
            setSavingPlans(trans.data);
            setTotalSavingsValue(trans.data.reduce((a, b) => { return a + b.amount }, 0));
            setTotalActiveSavingsValue(trans.data.reduce((a, b) => { if (b.status == "active") { return a + b.value } else return a + 0 }, 0))

            console.log(trans.data)
            setSavingPlansIsLoading(false);
        }
    }

    return <>
        <div className="font-lota lota-font g-rouzo-base-color h-screen w-screen p-1">
            <div className="bg-rouzo-deep-blue h-full w-full rounded-xl p-2">
                <div className="flex flex-row p-4">
                    <div className="font-bold text-white text-xl">
                        <p>Hi Admin,</p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="px-10 flex flex-col justify-center gap-4">
                    <TopNavComponent homeActive={true}></TopNavComponent>
                    <div className="flex flex-row gap-2 justify-center">
                        <div className="text-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {usersIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{users ? users.length : 0}</p>}
                                <p>Total Users</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {savingPlansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{savingPlans ? savingPlans.length : 0}</p>}
                                <p>Total SavingPlans Created</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {savingPlansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{Constants.nairaString}<FormatMoneyComponent amount={totalActiveSavingsValue ? totalActiveSavingsValue : 0}></FormatMoneyComponent></p>}
                                <p>Total SavingPlans Value</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {transactionsIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{transactions ? transactions.length : 0}</p>}
                                <p>Total Transactions</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                        <div className="ext-center flex flex-col bg-white rounded-md">
                            <div className="p-4 pb-0 text-center flex flex-col gap-2">
                                {!wallets ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{Constants.nairaString}<FormatMoneyComponent amount={transactionalValue ? transactionalValue : 0}></FormatMoneyComponent></p>}
                                <p>Total Transactional Value</p>
                            </div>
                            <div className="text-right">
                                <span className="text-right font-bold text-white p-1 rounded-tl-md text-xs bg-rouzo-base-color ">More</span>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row justify-end">
                            <div className="flex text-rouzo-light-blue-2 text-sm flex-row">
                                <p onClick={() => handleSwitchSummaryTimeline("today")} className={`px-1 hover:cursor-pointer  rounded-l-md  ${todayActive ? "bg-rouzo-base-color text-white" : "text-black bg-rouzo-light-blue-2"}`}>Today</p>
                                <p onClick={() => handleSwitchSummaryTimeline("thisWeek")} className={`px-1 hover:cursor-pointer text-black ${thisWeekActive ? "bg-rouzo-base-color text-white" : "text-black bg-rouzo-light-blue-2"}`}>This Week</p>
                                <p onClick={() => handleSwitchSummaryTimeline("thisMonth")} className={`px-1 hover:cursor-pointer  rounded-r-md text-black ${thisMonthActive ? "bg-rouzo-base-color text-white" : "text-black bg-rouzo-light-blue-2"}`}>This Month</p>

                            </div>

                        </div>
                        {todayActive ?
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 min-h-20">
                                <div className="rounded-md outline outline-2 outline-black bg-white p-2 flex flex-col gap-2">
                                    <p className="text-left text-rouzo-deep-blue text-sm ">Total New Signups</p>
                                    <div className="flex flex-row justify-between items-center">
                                        <FontAwesomeIcon className="text-2xl text-black" icon={faChartLine}></FontAwesomeIcon>
                                        <p className="text-3xl text-right font-bold ">{platformSummary ? platformSummary.today.users.length : 0}</p>
                                    </div>
                                </div>
                                <div className="rounded-md outline outline-2 outline-black bg-white p-2 flex flex-col gap-2">
                                    <p className="text-left text-rouzo-deep-blue text-sm ">Total New Saving Plans</p>
                                    <div className="flex flex-row justify-between items-center">
                                        <FontAwesomeIcon className="text-2xl text-black" icon={faChartLine}></FontAwesomeIcon>
                                        <p className="text-2xl text-right font-bold ">{platformSummary ? platformSummary.today.savingPlans.length : 0}</p>
                                    </div>
                                </div>
                            </div>
                            :
                            ""}
                        {thisWeekActive ?
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 min-h-20">
                                <div className="rounded-md outline outline-2 outline-black bg-white p-2 flex flex-col gap-2">
                                    <p className="text-left text-rouzo-deep-blue text-sm ">Total New Signups</p>
                                    <div className="flex flex-row justify-between items-center">
                                        <FontAwesomeIcon className="text-2xl text-black" icon={faChartLine}></FontAwesomeIcon>
                                        <p className="text-3xl text-right font-bold ">{platformSummary ? platformSummary.thisWeek.users.length : 0}</p>
                                    </div>
                                </div>
                                <div className="rounded-md outline outline-2 outline-black bg-white p-2 flex flex-col gap-2">
                                    <p className="text-left text-rouzo-deep-blue text-sm ">Total New Saving Plans</p>
                                    <div className="flex flex-row justify-between items-center">
                                        <FontAwesomeIcon className="text-2xl text-black" icon={faChartLine}></FontAwesomeIcon>
                                        <p className="text-3xl text-right font-bold ">{platformSummary ? platformSummary.thisWeek.savingPlans.length : 0}</p>
                                    </div>
                                </div>
                            </div>
                            :
                            ""}
                        {thisMonthActive ?
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 min-h-20">
                                <div className="rounded-md outline outline-2 outline-black bg-white p-2 flex flex-col gap-2">
                                    <p className="text-left text-rouzo-deep-blue text-sm ">Total New Signups</p>
                                    <div className="flex flex-row justify-between items-center">
                                        <FontAwesomeIcon className="text-2xl text-black" icon={faChartLine}></FontAwesomeIcon>
                                        <p className="text-3xl text-right font-bold ">{platformSummary ? platformSummary.thisMonth.users.length : 0}</p>
                                    </div>
                                </div>
                                <div className="rounded-md outline outline-2 outline-black bg-white p-2 flex flex-col gap-2">
                                    <p className="text-left text-rouzo-deep-blue text-sm ">Total New Saving Plans</p>
                                    <div className="flex flex-row justify-between items-center">
                                        <FontAwesomeIcon className="text-2xl text-black" icon={faChartLine}></FontAwesomeIcon>
                                        <p className="text-3xl text-right font-bold ">{platformSummary ? platformSummary.thisMonth.savingPlans.length : 0}</p>
                                    </div>
                                </div>
                            </div>
                            :
                            ""}

                    </div>
                </div>

            </div>


        </div>

    </>
}


export default AdminMainDashboardPage;