import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../context/AdminContext";

import AdminService from "../../api/services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniLoadingComponent from "../../components/MiniLoadingComponent";
import Constants from "../../constants/Constants";
import TopNavComponent from "../../components/admin/TopNavComponent";
import FormatMoneyComponent from "../../components/misc/FormatMoneyComponent";
import { Link } from "react-router-dom";


let WalletsPage = () => {

    let { wallets, setWallets } = useContext(AdminContext);
    let [walletsIsLoading, setWalletsIsLoading] = useState(false);
    let [lockedWallets, setLockedWallets] = useState(null);
    let [totalWalletsValue, setTotalWalletsValue] = useState(null);
    useEffect(() => {
        if (!wallets) {
            handleFetchWallets();
        }
        else if (wallets) {
            if (!lockedWallets) {
                handleLoadWallets()
            }
        }
    })

    let handleLoadWallets = async () => {
        if (wallets) {
            setLockedWallets(wallets.filter(o => o.isLocked == "true"))
            setTotalWalletsValue(wallets.reduce((a, b) => { return a + b.balance.stash }, 0))
        }
    }
    let handleFetchWallets = async () => {
        setWalletsIsLoading(true);
        let trans = await AdminService.fetchWallets();
        if (trans.success) {
            setWallets(trans.data);


            handleLoadWallets();
            setTimeout(() => {
                setWalletsIsLoading(false);
            }, 2000)

        }
    }

    return <>
        <div className="font-lota lota-font g-rouzo-base-color h-full w-screen p-1">
            <div className="bg-rouzo-deep-blue h-full w-full rounded-xl p-2">
                <div className="flex flex-row p-4">
                    <div className="font-bold text-white text-xl">
                        <p>Hi Admin,</p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="px-10 flex flex-col justify-center gap-4">
                    <TopNavComponent walletsActive={true}></TopNavComponent>
                    <div className="flex flex-row gap-2 justify-center">


                        <div className="flex flex-row items-center gap-1">

                            <div className="p-3 bg-white rounded-xl text-center flex flex-col gap-1">
                                {walletsIsLoading ? <MiniLoadingComponent fontSize={"text-[20px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{wallets ? wallets.length : 0}</p>}
                                <p className="text-xs font-bold">total wallets</p>
                            </div>

                            <div className="flex flex-col gap-1 ">
                                <div className="p-4  bg-white rounded-xl text-center flex flex-col gap-1">
                                    {walletsIsLoading ? <MiniLoadingComponent fontSize={"text-[20px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-rouzo-green-light">{wallets ? wallets.length : 0} <span className="text-xs text-black">active wallets</span></p>}
                                </div>
                                <div className="p-4 bg-white rounded-xl text-center flex flex-col gap-1">
                                    {walletsIsLoading ? <MiniLoadingComponent fontSize={"text-[20px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-rouzo-orange-defaultk">{lockedWallets ? lockedWallets.length : 0} <span className="text-xs text-black">locked wallets</span></p>}

                                </div>
                            </div>
                            <div className="p-3 bg-white rounded-xl text-center flex flex-col gap-1">
                                {walletsIsLoading ? <MiniLoadingComponent fontSize={"text-[20px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{Constants.nairaString}<FormatMoneyComponent amount={totalWalletsValue ? totalWalletsValue : 0}></FormatMoneyComponent></p>}
                                <p className="text-xs font-bold">total wallets value</p>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col gap-2 md:grid md:grid-cols-3 ">
                        {wallets ?
                            wallets.map((wallet) => {
                                return <div className="rounded-md flex flex-col bg-white justify-between p-4 pb-2">
                                    <div className="flex flex-row justify-end text-right text-nowrap gap-2">
                                        <p className="bg-rouzo-base-color text-white font-bold px-2 truncate rounded-md text-nowrap">{wallet.userDetails[0].fullName}</p>
                                    </div>
                                    <div className="flex flex-col">
                                        <p className="text-black text-xs text-left">Stash Balance</p>
                                        <div className="flex flex-row items-center">
                                            <p className="text-xs">{Constants.nairaString}</p>
                                            <p className="font-bold text-xl text-rouzo-green-light"><FormatMoneyComponent amount={wallet.balance.stash}></FormatMoneyComponent></p>
                                        </div>
                                    </div>
                                    <Link to={`/admin/dashboard/user/${wallet.userId}`}>
                                        <div className="mt-2 rounded-md hover:bg-rouzo-base-color hover:text-white bg-rouzo-light-blue-2 ">
                                            <p>View user</p>
                                        </div>
                                    </Link>
                                </div>
                            }) : ""}
                    </div>
                </div>

            </div>


        </div>
    </>
}


export default WalletsPage;