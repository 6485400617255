
import { useContext, useEffect, useState } from "react";
import { AdminContext } from "../../context/AdminContext";

import AdminService from "../../api/services/AdminService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MiniLoadingComponent from "../../components/MiniLoadingComponent";
import Constants from "../../constants/Constants";
import TopNavComponent from "../../components/admin/TopNavComponent";
import FormatMoneyComponent from "../../components/misc/FormatMoneyComponent";
import AdminCardDetailComponent from "../../components/misc/AdminCardDetailComponent";
import SavingplansListComponent from "../../components/admin/SavingplansListComponent";


let SavingPlansPage = () => {


    let { savingPlans, setSavingPlans } = useContext(AdminContext);
    let [savingplansIsLoading, setSavingPlansIsLoading] = useState(false);
    let [successfulSavingPlans, setSuccessfulSavingPlans] = useState(null);
    let [pendingSavingPlans, setPendingSavingPlans] = useState(null);
    let [failedSavingPlans, setFailedSavingPlans] = useState(null);
    let [totalActiveSavingsValue, setTotalActiveSavingsValue] = useState(null);
    let [agbaBallerSavers, setAgbaBallerSavers] = useState(null);
    let [noGreeForAnybodySavers, setNoGreeForAnybodySavers] = useState(null);
    let [odogwuSavers, setOdogwuSavers] = useState(null);
    let [echokeSavers, setEchokeSavers] = useState(null);
    let [profprofSavers, setProfProfSavers] = useState(null);
    let [deyPlaySavers, setDeyPlaySavers] = useState(null);
    let [atAllAtAllSavers, setAtAllAtAllSavers] = useState(null);

    useEffect(() => {
        if (!savingPlans) {
            handleFetchSavingPlans();
        }
        else if (savingPlans) {
            if (!successfulSavingPlans) {
                handleLoadSavingPlans()
            }
        }
    })

    let handleLoadSavingPlans = async () => {
        if (savingPlans) {
            setSuccessfulSavingPlans(savingPlans.filter(o => o.status == "active"));
            setPendingSavingPlans(savingPlans.filter(o => o.status == "pending"));
            setFailedSavingPlans(savingPlans.filter(o => o.status == "failed"));
            setTotalActiveSavingsValue(savingPlans.reduce((a, b) => { if (b.status == "active") { return a + b.value } else return a + 0 }, 0));

            setAgbaBallerSavers({
                savers: savingPlans.filter(o => o.savingChallengeType == "agbaballer"),
                value: savingPlans.reduce((a, b) => { if (b.status == "active" && b.savingChallengeType == "agbaballer") { return a + b.amount } else return a + 0 }, 0)
            })
            setProfProfSavers({
                savers: savingPlans.filter(o => o.savingChallengeType == "profprof"),
                value: savingPlans.reduce((a, b) => { if (b.status == "active" && b.savingChallengeType == "profprof") { return a + b.amount } else return a + 0 }, 0)
            })
            setOdogwuSavers({
                savers: savingPlans.filter(o => o.savingChallengeType == "odogwu"),
                value: savingPlans.reduce((a, b) => { if (b.status == "active" && b.savingChallengeType == "odogwu") { return a + b.amount } else return a + 0 }, 0)
            })
            setEchokeSavers({
                savers: savingPlans.filter(o => o.savingChallengeType == "echoke"),
                value: savingPlans.reduce((a, b) => { if (b.status == "active" && b.savingChallengeType == "echoke") { return a + b.amount } else return a + 0 }, 0)
            })
            setNoGreeForAnybodySavers({
                savers: savingPlans.filter(o => o.savingChallengeType == "nogreeforanybody"),
                value: savingPlans.reduce((a, b) => { if (b.status == "active" && b.savingChallengeType == "nogreeforanybody") { return a + b.amount } else return a + 0 }, 0)
            })
            setAtAllAtAllSavers({
                savers: savingPlans.filter(o => o.savingChallengeType == "atallatall"),
                value: savingPlans.reduce((a, b) => { if (b.status == "active" && b.savingChallengeType == "atallatall") { return a + b.amount } else return a + 0 }, 0)
            })
            setDeyPlaySavers({
                savers: savingPlans.filter(o => o.savingChallengeType == "deyplay"),
                value: savingPlans.reduce((a, b) => { if (b.status == "active" && b.savingChallengeType == "deyplay") { return a + b.amount } else return a + 0 }, 0)
            })
        }
    }
    let handleFetchSavingPlans = async () => {
        setSavingPlansIsLoading(true);
        let trans = await AdminService.fetchSavingPlans();
        if (trans.success) {
            setSavingPlans(trans.data);

            handleLoadSavingPlans();
            setTimeout(() => {
                setSavingPlansIsLoading(false);
            }, 2000)

        }
    }

    return <>
        <div className="font-lota lota-font g-rouzo-base-color h-full w-screen p-1">
            <div className="bg-rouzo-deep-blue h-full w-full rounded-xl p-2">
                <div className="flex flex-row p-4">
                    <div className="font-bold text-white text-xl">
                        <p>Hi Admin,</p>
                    </div>
                    <div>

                    </div>
                </div>
                <div className="px-10 flex flex-col justify-center gap-4">
                    <TopNavComponent savingplansActive={true}></TopNavComponent>
                    <div className="flex flex-row gap-4 overflow-x-scroll justify-center">

                        <div className="flex flex-col gap-2">
                            <p className="font-bold text-white text-left">Summary</p>
                            <div className="flex flex-row gap-2">
                                <div className="ext-center flex flex-col bg-white rounded-md">
                                    <div className="p-3  text-center flex flex-col gap-1">
                                        {savingplansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{savingPlans ? savingPlans.length : 0}</p>}
                                        <p className="font-bold">Total Saving Plans</p>
                                    </div>

                                </div>
                                <div className="flex flex-row items-center gap-1">

                                    <div className="p-3 bg-white rounded-xl text-center flex flex-col gap-1">
                                        {savingplansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-[50px] font-bold text-rouzo-green-light">{successfulSavingPlans ? successfulSavingPlans.length : 0}</p>}
                                        <p className="text-xs font-bold">successful saving plans</p>
                                    </div>

                                    <div className="flex flex-col gap-1 ">
                                        <div className="p-4  bg-white rounded-xl text-center flex flex-col gap-1">
                                            {savingplansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-rouzo-orange-default">{failedSavingPlans ? failedSavingPlans.length : 0} <span className="text-xs text-black">failed saving plans</span></p>}
                                        </div>
                                        <div className="p-4 bg-white rounded-xl text-center flex flex-col gap-1">
                                            {savingplansIsLoading ? <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}></MiniLoadingComponent> : <p className="text-md font-bold text-black">{pendingSavingPlans ? pendingSavingPlans.length : 0} <span className="text-xs text-black">pending saving plans</span></p>}

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                            <p className="font-bold text-white text-left">Transaction Value</p>
                            <div className="flex flex-row gap-2">
                                <div className="flex flex-row items-center gap-1">
                                    <div className="p-3 bg-white rounded-xl text-center flex flex-col gap-1">
                                        {savingplansIsLoading ?
                                            <MiniLoadingComponent fontSize={"text-[50px]"} color={"text-rouzo-green-light"}>

                                            </MiniLoadingComponent>
                                            :
                                            <p className="text-[40px] font-bold text-rouzo-green-light">
                                                <p className="text-xs text-right">NGN</p>
                                                {totalActiveSavingsValue ?
                                                    <FormatMoneyComponent amount={totalActiveSavingsValue}></FormatMoneyComponent>
                                                    : 0}
                                            </p>}
                                        <p className="text-xs font-bold">total savingplans value</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="flex flex-col gap-2">
                            <p className="font-bold text-white text-left">Challenges Summary</p>
                            <div className="flex flex-row gap-2 items-center">
                                <div className="flex flex-col items-center gap-1">
                                    <AdminCardDetailComponent padding={"p-2"} isLoading={savingplansIsLoading} cardValue={agbaBallerSavers ? agbaBallerSavers.savers.length : 0} title={"abgaballer saver(s)"}></AdminCardDetailComponent>
                                    <AdminCardDetailComponent padding={"p-2"} isLoading={savingplansIsLoading} cardValue={noGreeForAnybodySavers ? noGreeForAnybodySavers.savers.length : 0} title={"nogreeforanybody saver(s)"}></AdminCardDetailComponent>
                                </div>
                                <div className="flex flex-col gap-2 items-center">
                                    <div className="flex flex-row gap-1">
                                        <AdminCardDetailComponent padding={"p-1"} isLoading={savingplansIsLoading} cardValue={odogwuSavers ? odogwuSavers.savers.length : 0} title={"odogwu saver(s)"}></AdminCardDetailComponent>
                                        <AdminCardDetailComponent padding={"p-1"} isLoading={savingplansIsLoading} cardValue={echokeSavers ? echokeSavers.savers.length : 0} title={"echoke saver(s)"}></AdminCardDetailComponent>
                                        <AdminCardDetailComponent padding={"p-1"} isLoading={savingplansIsLoading} cardValue={profprofSavers ? profprofSavers.savers.length : 0} title={"profprof saver(s)"}></AdminCardDetailComponent>
                                    </div>
                                    <div className="flex flex-row gap-1">
                                        <AdminCardDetailComponent padding={"p-1"} isLoading={savingplansIsLoading} cardValue={deyPlaySavers ? deyPlaySavers.savers.length : 0} title={"deyplay saver(s)"}></AdminCardDetailComponent>
                                        <AdminCardDetailComponent padding={"p-1"} isLoading={savingplansIsLoading} cardValue={atAllAtAllSavers ? atAllAtAllSavers.savers.length : 0} title={"atallatall saver(s)"}></AdminCardDetailComponent>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-row justify-center">
                        <SavingplansListComponent></SavingplansListComponent>
                    </div>
                </div>

            </div>


        </div>
    </>
}


export default SavingPlansPage;